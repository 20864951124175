import ReportController from '../controllers/report-controller'
import { computed } from '@ember/object';
import RouteMeta from '../mixins/route-meta';

export default ReportController.extend(RouteMeta, {

    queryParams: ['since', 'until'], //This is where the query params exist
    // queryParams: ['since', 'until','isRefresh'],
    since:null,
    until:null,
    // isRefresh: null,
    pickerUntil: null,
    pickerSince :null,
    
    dateRangePickerclass : computed('media.isLegacy', function(){
        if (this.get('media.isLegacy')){
            return "flex";
        }
        return "layout-row layout-align-end-center";
    }),

    actions : {
        applyDateRange(since, until){
            this.setProperties({'since':since.format('YYYY-MM-DD'), 'until':until.format('YYYY-MM-DD')});
        },
    }

});
