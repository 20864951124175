define("fundy-insights/components/light-table", ["exports", "ember-light-table/components/light-table"], function (_exports, _lightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _lightTable.default;
    }
  });
});
