import RangedReportRoute from '../routes/ranged-report-route';
import { getOwner } from "@ember/application";

export default RangedReportRoute.extend({

    revenueAdapter : null,

    beforeModel(){
        this._super(...arguments);
        this.controllerFor('application').set('activeReport', this.get('routeMeta.revenue.name'));
        this.controllerFor('loading').set('activeReport', this.get('routeMeta.revenue.name'));
        let applicationInstance = getOwner(this);
        this.set('revenueAdapter', applicationInstance.lookup('adapter:insights'));
    },

    model(params){

        this._super(...arguments);
        let self = this;
        let since = this.since;
        let until = this.until;

        let cachedReport = this.getCachedReport();
        if (cachedReport){
            return cachedReport;
        }

        let revenueAdapter = this.revenueAdapter;
        let reportObject = null;

        return revenueAdapter.getRevenueReport(since, until).then(
            function(result){
                reportObject=result;
                const blankLine = {
                    label: "",
                };
                console.log(result);
                let report = [
                    reportObject.v11ProSuite,
                    reportObject.v11ProSuiteUpgraded,
                    reportObject.v11AlbumSuite,
                    reportObject.v10ProSuite,
                    reportObject.v10ProSuiteYearLeaseSignup,
                    reportObject.v10ProSuiteYearLeaseRenew,
                    reportObject.v10ProSuiteYearLeaseUpgrades,
                    reportObject.v10ProSuiteMonthLeaseSignup,
                    reportObject.v10ProSuiteMonthLeaseRenew,
                    reportObject.v10ProSuiteMonthLeaseUpgrades,
                    reportObject.upgrades,
                    reportObject.v10ProSuiteCrossgrade,
                    reportObject.v10AlbumSuite,
                    reportObject.proEnhancements,
                    reportObject.proEnhancementsReOptIn,
                    reportObject.oldProofer,
                    reportObject.undetermined,
                    reportObject.directGross,
                    reportObject.directNet,
                    blankLine,
                    reportObject.totalGross,
                    reportObject.totalNet,
                    blankLine,
                    reportObject.newRev,
                    reportObject.saasRev
                ]
                let retVal = {reportObject:reportObject,  report:report};
                self.cacheReport(retVal);
                return retVal;
            }
        )

     }

});
