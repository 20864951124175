import RangedReportRoute from '../routes/ranged-report-route'
import { getOwner } from '@ember/application';
import formatMoney from "accounting/format-money";

export default RangedReportRoute.extend({

    insightsAdapter : null,

    beforeModel(){
        this._super(...arguments);
        this.controllerFor('application').set('activeReport', this.get('routeMeta.analytics.name'));
        this.controllerFor('loading').set('activeReport', this.get('routeMeta.analytics.name'));
        let applicationInstance = getOwner(this);
        this.set('insightsAdapter', applicationInstance.lookup('adapter:insights'));
    },

    model(params){  
        this._super(...arguments);
        let self = this;
        let since = this.since;
        let until = this.until;

        let cachedReport = this.getCachedReport();
        if (cachedReport){
           return cachedReport;
        }

       let insightsAdapter = this.insightsAdapter;
       let reportObject = null;
        
        return insightsAdapter.getAnalyticsReport(since, until).then(

           function(result){
                reportObject=result;
                reportObject.conversionToTrial.value = `${reportObject.conversionToTrial.value}%`;
                reportObject.conversionToPurchase.value = `${reportObject.conversionToPurchase.value}%`;
                reportObject.valuePerUser.value = formatMoney(reportObject.valuePerUser.value);
                let report = [
                 reportObject.users,
                 reportObject.sessions,
                 reportObject.pageViews,
                 reportObject.conversionToTrial,
                 reportObject.conversionToPurchase,
                 reportObject.valuePerUser
             ]
              
             let retVal = {reportObject:reportObject,  report:report};
             self.cacheReport(retVal); 
             return retVal;
            }
        );


    }

});
