import Controller from '@ember/controller';
import { inject as Service } from '@ember/service';
import moment from 'moment';
import { getOwner } from '@ember/application';

export default Controller.extend({    
    activeReport:'',
    queryParams: ['id'],
    authAdapter : null,
    session: Service(),
    userIdle: Service(),

    init(){
        this._super(...arguments);
        let owner = getOwner(this);
        this.set('authAdapter', owner.lookup('adapter:auth'));
        this.checkSession();
    },

    checkSession(){
        let session = this.session;
        let self = this;
        setInterval(function(){
            let data = session.get('data');
            if (data && data.authenticated){
                if (!data.authenticated.expires){
                    return;
                }
                let expiration = new moment(data.authenticated.expires);
                let now = new moment();
                if (now.isAfter(expiration)) {
                    if (self.get('userIdle.isIdle')){
                        session.invalidate();
                    }else{
                        session.refreshSession('authenticator:fs-auth');
                    }
                }
            }
        }, 5000);  
    },
});
