import Mixin from '@ember/object/mixin';
import { inject } from '@ember/service';

export default Mixin.create({

    router: inject(),

    routeMeta :  {

        selectReportPrompt : "Choose a Report",

        revenue : {
            name: "Revenue",
            icon: "account-balance",
            route: "revenue" 
        },

        users :  {
            name: "Users",
            icon: "group",
            route: "users"
        },

        proEnhancements : {
            name: "Renewals",
            icon: "all-inclusive",
            route: "pro-enhancements"
        },

        opportunities : {
             name: "Opportunities",
             icon: "local-bar",
            route: "opportunities"
        },

        projectedRevenue : {
            name: "Projected Revenue",
            icon: "attach-money",
            route: "projected-revenue"
        },

        analytics : {
            name: "Analytics",
            icon: "trending-up",
            route: "analytics" 
        },

        conversionToPurchase : {
            name: "Purchase Conversion",
            icon: "how-to-reg",
            route: "conversion-to-purchase" 
        },
    },

    actions : {

        goToReport: function(report){
            this.router.transitionTo(this.get(`routeMeta.${report}.route`));
        },

    }

});
