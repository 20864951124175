import Mixin from '@ember/object/mixin';
import { inject } from '@ember/service';
import { reads } from '@ember/object/computed';

export default Mixin.create({

    kpi : inject(),

    analyticsKPIs : reads('kpi.analyticsKPIs'),
    opportunityKPIs :reads('kpi.opportunityKPIs'),
    revenueKPIs:reads('kpi.revenueKPIs'),
    proEnhancementsKPIs:reads('kpi.proEnhancementsKPIs'),
    projectedRevenueKPIs:reads('kpi.projectedRevenueKPIs'),
    usersKPIs:reads('kpi.usersKPIs'),
    conversionToPurchaseKPIs:reads('kpi.conversionToPurchaseKPIs')


});
