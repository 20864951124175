import Service from '@ember/service';
import { getOwner } from "@ember/application";
import moment from 'moment';
import formatMoney from "accounting/format-money";
import { inject } from '@ember/service';
import EmberObject from '@ember/object';

export default Service.extend({

    media : inject(),
    refreshQueue : null,
    insightsAdapter : null,
    next:0,

    analyticsKPIs :null,
    opportunityKPIs :null,
    revenueKPIs:null,
    projectedRevenueKPIs:null,
    proEnhancementsKPIs:null,
    usersKPIs:null,
    conversionToPurchaseKPIs:null,
    
    userKPIsNewTrial : 'New Trial',
    userKPIsNewPurchases : 'New Purchases',
    opportunityKPIsUpgrades : 'Upgrades',
    opportunityKPIsProEnhancements : 'Pro Enhancements',
    analyticsKPIsTrial : 'Trial Conversion',
    analyticsKPIsPurchase : 'Purchase Conversion',
    revenueKPIsMonth : 'Current Month',
    revenueKPIsYear : 'Year to Date',
    proEnhancementsKPIsMonth : 'Current Month',
    proEnhancementsKPIsYear : 'Year to Date',
    conversionToPurchaseKPIsDay : 'Same Day',
    conversionToPurchaseKPIsWeek : 'One Week',
    projectedRevenueKPIsRecurring : 'Recurring',
    projectedRevenueKPIsTotal : 'Total',

    init(){

        this._super(...arguments);
        this.set('insightsAdapter', getOwner(this).lookup('adapter:insights'));
        this.initMetrics();
        let refreshQueue = [];
        refreshQueue.push(
            this.getAnalyticsReport, 
            this.getProductOwnershipReport, 
            this.getRevenueReport, 
            // this.getProEnhancementsBillingReport,
            this.getOpportunitiesReport,
            this.getConversionToPurchaseReport
        );
        this.set('refreshQueue', refreshQueue);
        this.setInitialReportData();
        setInterval(this.processNext, 240000, this)
    },

    initMetrics(){
    
        let analyticsKPIs = this.createMetric(this.analyticsKPIs, this.analyticsKPIsTrial);
        analyticsKPIs = this.createMetric(analyticsKPIs, this.analyticsKPIsPurchase);
        this.set('analyticsKPIs',  analyticsKPIs);
        
        let opportunityKPIs = this.createMetric(this.opportunityKPIs, this.opportunityKPIsUpgrades);
        opportunityKPIs = this.createMetric(opportunityKPIs, this.opportunityKPIsProEnhancements);
        this.set('opportunityKPIs', opportunityKPIs);

        let revenueKPIs = this.createMetric(this.revenueKPIs, this.revenueKPIsMonth);
        revenueKPIs = this.createMetric(revenueKPIs, this.revenueKPIsYear);
        this.set('revenueKPIs',revenueKPIs);

        let projectedRevenueKPIs = this.createMetric(this.projectedRevenueKPIs, this.projectedRevenueKPIsRecurring);
        projectedRevenueKPIs = this.createMetric(projectedRevenueKPIs, this.projectedRevenueKPIsTotal);
        this.set('projectedRevenueKPIs',projectedRevenueKPIs);

        let proEnhancementsKPIs = this.createMetric(this.proEnhancementsKPIs, this.proEnhancementsKPIsMonth);
        proEnhancementsKPIs = this.createMetric(proEnhancementsKPIs, this.proEnhancementsKPIsYear);
        this.set('proEnhancementsKPIs',proEnhancementsKPIs);
       
        let usersKPIs = this.createMetric(this.usersKPIs, this.userKPIsNewTrial);
        usersKPIs = this.createMetric(usersKPIs, this.userKPIsNewPurchases);
        this.set('usersKPIs',usersKPIs);

        let conversionToPurchaseKPIs = this.createMetric(this.conversionToPurchaseKPIs, this.conversionToPurchaseKPIsDay);
        conversionToPurchaseKPIs = this.createMetric(conversionToPurchaseKPIs, this.conversionToPurchaseKPIsWeek);
        this.set('conversionToPurchaseKPIs',conversionToPurchaseKPIs);
    },

    createMetric(metrics, name){

        if (!metrics){
            metrics = [];
        }

        let metric = EmberObject.create();
        metric.set('name',  name);
        metric.set('value',  '?');
        metrics.pushObject(metric);
        return metrics;
    },

    updateMetric(metrics, name, value){
        metrics.forEach(function(metric){
            if (metric.get('name') == name){
                metric.set('value', value);
            }
        })
    },

    getRange(yearToDate){
        let now = moment();        
        let month = now.month();
        if (yearToDate){
            month=0  
        }  
        let since = moment({year: now.year(), month : month, day:1}).format("YYYY-MM-DD");  
        let until = moment().format("YYYY-MM-DD");  
        return {since:since, until:until}     
    },

    getMonthToEndRange(){
        let now = moment();        
        let since = now.format("YYYY-MM-DD");  
        let until = now.endOf('month').format("YYYY-MM-DD");  
        return {since:since, until:until}     
    },

    setInitialReportData(){
        let self = this;
        this.refreshQueue.forEach(getReport => {
            getReport(self);
        });
    },

    processNext(context){

        if (context.next >= context.refreshQueue.length -1){
            return;
        }

        context.refreshQueue[context.next](context);
        context.next = context.next >= context.refreshQueue.length -1 ? 0 : context.next + 1;
    },

    getProductOwnershipReport(context){
        let range = context.getRange();
        context.get('insightsAdapter').getProductOwnershipReport(range.since, range.until).then(
            function(result){
                context.updateMetric(context.get('usersKPIs'), context.userKPIsNewTrial, result.trial.newPurchases);
                context.updateMetric(context.get('usersKPIs'), context.userKPIsNewPurchases, result.proSuite.newPurchases
                 + result.albumSuite.newPurchases 
                 + result.liteSuite.newPurchases + result.proSuiteV10.newPurchases + result.albumSuiteV10.newPurchases + result.proSuiteV10Lease.newPurchases);

        });

    },
    
    getOpportunitiesReport(context){
        context.get('insightsAdapter').getOpportunitiesReport(moment().format("YYYY-MM-DD")).then(
           function(result){
                context.updateMetric(context.get('opportunityKPIs'), context.opportunityKPIsUpgrades, formatMoney(result.v6Users.value + result.v7Users.value));
                context.updateMetric(context.get('opportunityKPIs'), context.opportunityKPIsProEnhancements, formatMoney(result.noProEnhancements.value));
           }
       )
    },

    getAnalyticsReport(context){
        let range = context.getRange();
        context.get('insightsAdapter').getAnalyticsReport(range.since, range.until).then(function(result){
            context.updateMetric(context.get('analyticsKPIs'), context.analyticsKPIsTrial, `${result.conversionToTrial.value}%`);
            context.updateMetric(context.get('analyticsKPIs'), context.analyticsKPIsPurchase, `${result.conversionToPurchase.value}%`);
        });
    },

    getRevenueReport(context){
        let range = context.getRange();
        context.get('insightsAdapter').getRevenueReport(range.since, range.until).then(
            function(result){
                let recurringToDate = result.proEnhancements.total + result.oldProofer.total + result.v10ProSuiteMonthLeaseRenew.total + result.v10ProSuiteYearLeaseRenew.total;
                 context.updateMetric(context.get('revenueKPIs'), context.revenueKPIsMonth, formatMoney(result.totalNet.total));
                 context.updateMetric(context.get('proEnhancementsKPIs'), context.proEnhancementsKPIsMonth, formatMoney(recurringToDate));
                 context.getProjectedRevenueReport(context, result);
            }
        );

        range = context.getRange(true);
        context.get('insightsAdapter').getRevenueReport(range.since, range.until).then(
            function(result){
                let recurringYearToDate = result.proEnhancements.total + result.oldProofer.total + result.v10ProSuiteMonthLeaseRenew.total + result.v10ProSuiteYearLeaseRenew.total;
                context.updateMetric(context.get('proEnhancementsKPIs'), context.proEnhancementsKPIsYear, formatMoney(recurringYearToDate)); 
                context.updateMetric(context.get('revenueKPIs'), context.revenueKPIsYear, formatMoney(result.totalNet.total)); 
            }
        );

    },
    
    // getProEnhancementsBillingReport(context){

    //     let range = context.getRange();
    //     context.get('insightsAdapter').getProEnhancementsBillingReport(range.since, range.until).then(
    //         function(result){

    //             console.log('result');
    //             context.updateMetric(context.get('proEnhancementsKPIs'), context.proEnhancementsKPIsMonth, formatMoney(result.total.amount)); 
    //         }
    //     )

    //     range = context.getRange(true);
    //     context.get('insightsAdapter').getProEnhancementsBillingReport(range.since, range.until).then(
    //         function(result){
    //             context.updateMetric(context.get('proEnhancementsKPIs'), context.proEnhancementsKPIsYear, formatMoney(result.total.amount));  
    //         }
    //     )
       
    // },

    getConversionToPurchaseReport(context){
        context.get('insightsAdapter').getConversionToPurchaseReport().then(
            function(result){
               context.updateMetric(context.get('conversionToPurchaseKPIs'), context.conversionToPurchaseKPIsDay, `${result.sameDay.value}%`);  
               context.updateMetric(context.get('conversionToPurchaseKPIs'), context.conversionToPurchaseKPIsWeek, `${result.oneWeek.value}%`);       
             }
         );
    },
    
    getProjectedRevenueReport(context, currentMonthRevenue){
        let recurringToDate = currentMonthRevenue.proEnhancements.total + currentMonthRevenue.oldProofer.total + currentMonthRevenue.v10ProSuiteMonthLeaseRenew.total + currentMonthRevenue.v10ProSuiteYearLeaseRenew.total;
        let totalToDate = currentMonthRevenue.totalNet.total;
        let now = moment();
        let numDaysSinceBOT = now.date()
        let numDaysInMonth = now.endOf('month').date();  
        let avg = totalToDate/numDaysSinceBOT;
        let projectionBasedOnAvg = avg * numDaysInMonth;
       
        let range = context.getMonthToEndRange();
        console.log(range)
            context.get('insightsAdapter').getProjectedRevenueReport(range.since, range.until).then(    
                function(result){
                    context.updateMetric(context.get('projectedRevenueKPIs'), context.projectedRevenueKPIsRecurring, formatMoney(recurringToDate) + '/' +   formatMoney(result.totalProjection.totalAmount + recurringToDate));  
                    context.updateMetric(context.get('projectedRevenueKPIs'), context.projectedRevenueKPIsTotal, formatMoney(totalToDate) + '/' +   formatMoney(projectionBasedOnAvg));      
            }
        )  
    },

});
