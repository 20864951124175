import RangedReportRoute from '../routes/ranged-report-route'
import RouteMeta from '../mixins/route-meta';
import { getOwner } from '@ember/application';

export default RangedReportRoute.extend(RouteMeta, {

    insightsAdapter : null,

    beforeModel(){
        this._super(...arguments);
        this.controllerFor('application').set('activeReport', this.get('routeMeta.conversionToPurchase.name'));
        this.controllerFor('loading').set('activeReport', this.get('routeMeta.conversionToPurchase.name'));
        let applicationInstance = getOwner(this);
        this.set('insightsAdapter', applicationInstance.lookup('adapter:insights'));
    },

    model(params){  
        this._super(...arguments);
        let self = this;
        let cachedReport = this.getCachedReport();
        if (cachedReport){
           return cachedReport;
        }

       let insightsAdapter = this.insightsAdapter;
       let reportObject = null;
        return insightsAdapter.getConversionToPurchaseReport().then(

           function(result){
                reportObject=result;
                let report = [
                 reportObject.sameDay,
                 reportObject.oneWeek,
                 reportObject.oneMonth,
                 reportObject.threeMonths,
                 reportObject.sixMonths,
                 reportObject.oneYear,
                 reportObject.overOneYear,
             ]
             let retVal = {reportObject:reportObject,  report:report};
             self.cacheReport(retVal); 
             return retVal;
            }
        );


    }

});
