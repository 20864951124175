import RangedReport from '../controllers/ranged-report-controller'
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default RangedReport.extend({
    router: service('router'),

    reportCache: service('report-cache'),

    tableTitles: computed('model.reportObject', function() {
      let reportList = this.get('model.reportObject');
      let titleList = [];
      reportList.forEach(report => titleList.push(report.title))
      return titleList
    }),

    columns: computed(function() {
        return [
        {
            width: '30px',
            sortable: false,
            cellComponent: 'row-toggle',
            breakpoints: ['legacy', 'mobile']
        },
        {
          label: '',
          valuePath: 'name',
          width: '200px',
          cellComponent: 'table-cell-key',
          breakpoints: ['legacy', 'mobile', 'tablet', 'desktop', 'jumbo']
        }, 
        {
          valuePath: 'userCount',
          cellComponent:'table-cell-percent',
          width: '120px',
          breakpoints: ['mobile', 'tablet', 'desktop', 'jumbo']
        }, 
    ];
    }),
    actions : {
      async refresh() {
        this.set('reportCache.isRefresh',true)
        //setting the 'until' param to new Date() is a hack to force the model to regenerate after refresh.
        //This report is not for a time range so resetting the date param will not affect the report data.
        await this.router.transitionTo({queryParams: {until: new Date()}});
      }
    }

});
