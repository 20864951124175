import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import RouteMeta from '../mixins/route-meta';
import ReportCache from '../mixins/report-cache';
import { isEmpty } from '@ember/utils';
import moment from 'moment';

export default Route.extend(AuthenticatedRouteMixin, RouteMeta, ReportCache,{

    since: null,
    until : null,
   
    queryParams: {
        since: {
            refreshModel: true
        },
        until: {
            refreshModel: true
        },
      },

      model(queryParams){
        console.log('debug1')
        let since = queryParams.since;
        let until = queryParams.until;
        let now = moment();
        if (isEmpty(since)){
            since = moment({year: now.year(), month : now.month(), day:1}).format("YYYY-MM-DD");  
        }
        if (isEmpty(until)){
            until = moment().format("YYYY-MM-DD");  
        }

        this.set('since',since); 
        this.set('until',until); 

        let reportName = this.controllerFor('application').get('activeReport');
        let reportKey = `${reportName}_${since}_${until}`
        this.set('reportKey', reportKey);

      },


      setupController(controller) {
        this._super(...arguments);
        controller.set('pickerSince', this.since);
        controller.set('pickerUntil', this.until);
    },

    actions: {

    }
     
   });
