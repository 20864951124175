import Component from '@ember/component';
import { inject } from '@ember/service';
import RouteMeta from '../mixins/route-meta';

export default Component.extend(RouteMeta, {

    session: inject('session'),
    sessionData: inject('session-data'),

    actions: {
        invalidateSession(){
            this.session.invalidate();
        }
    }

});
