import RangedReport from '../controllers/ranged-report-controller'
import { computed } from '@ember/object';

export default RangedReport.extend({

    columns: computed(function() {
        return [
        {
            width: '30px',
            sortable: false,
            cellComponent: 'row-toggle',
            breakpoints: ['legacy', 'mobile']
        }, 
        {
          label: '',
          valuePath: 'name',
          width: '200px',
          cellComponent: 'table-cell-key',
          breakpoints: ['legacy', 'mobile', 'tablet', 'desktop', 'jumbo']
        }, 
        {
          label: 'New',
          valuePath: 'newPurchases',
          width: '90px',
          breakpoints: ['legacy', 'mobile', 'tablet', 'desktop', 'jumbo']
        }, 
        {
          label: 'Total',
          valuePath: 'total',
          width: '90px',
         
          breakpoints: ['mobile', 'tablet', 'desktop', 'jumbo']
        }
    ];
    }),

});
