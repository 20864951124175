import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({

size:'lg',

iconSize: computed('size', function(){

    let size = this.size;
    if (size==='lg'){
        return 128;
    }else{
        return 32;
    }

})



});
