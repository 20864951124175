import EmberRouter from '@ember/routing/router';
import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function() {
  this.route('revenue');
  this.route('users');
  this.route('projected-revenue');
  this.route('opportunities');
  this.route('analytics');
  this.route('pro-enhancements');
  this.route('dashboard');
  this.route('conversion-to-purchase');
});
