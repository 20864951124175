import Component from '@ember/component';
import { computed } from '@ember/object'
import { inject } from '@ember/service'
import moment from 'moment';
import $ from 'jquery';
import { inject as Service } from '@ember/service';

export default Component.extend({
    
    router: Service(),
    startDate : null,
    endDate : null,

    format : "MM/DD/YYYY",
    
    paperToaster: inject('paper-toaster'),

    awaitingInput : computed('startDate', 'endDate', function(){
        
        if (!this.startDate || !this.endDate){
            return true;
        }
        
        return this.checkRangeErrors();

    }),

    didInsertElement(){
        this.updateStartDate();
        this.updateEndDate();
    },

    checkRangeErrors(){
        let startDate = this.startDate;
        let endDate = this.endDate;
        console.log("route: " + this.router.currentRouteName);
        let today = new Date()
        today.setHours(0,0,0,0)

        console.log(startDate < today);
        if ( endDate && startDate && endDate.isBefore(startDate)){
           return "'To' date cannot be prior to 'From' date."
        }
        if ( startDate < today && this.router.currentRouteName == "projected-revenue") {
            return "'From' date for projections must be on or after current date."
        }
        return null;
    },

    alertIfErr(){
        let err= this.checkRangeErrors();
        if (err){
            this.paperToaster.show(err, { toastClass:'md-warn' });
        }
    },

    getPicker(kind){
        let picker = null;
        if (this.get('media.isLegacy')){
            picker = $("#mobileReport" + kind + "Picker")[0];
        }else{
            picker = $("#report" + kind + "Picker")[0];
        }
        return picker;
    },

    updateStartDate(){
        let picker = this.getPicker("Start")
        let format = this.format;
        let m =moment(picker.value, format)
        this.set('startDate',m);
        this.alertIfErr();
       
    },
    updateEndDate(){
        let picker = this.getPicker("End")
        let format = this.format;
        let m =moment(picker.value, format)
        this.set('endDate',m);
        this.alertIfErr();
    },
        
    actions: {
        updateStartDate(){
           this.updateStartDate();
           
        },
        updateEndDate(){
           this.updateEndDate();
        },
        apply(){
            this.applyDateRange(this.startDate, this.endDate);
        }
    }

});
