import AppAdapter from 'fundy-web-auth/adapters/application'
import Inflector from 'ember-inflector';

export default AppAdapter.extend({
  
  urlForFindRecord(id, modelName, snapshot) {
    let inflector = new Inflector(Inflector.defaultRules);
    let modelEndpoint = inflector.pluralize(modelName);
    let baseUrl = this.host;
    let fullUrl = `${baseUrl}/${modelEndpoint}/query?id=${id}`;
    return fullUrl;
  },
  

});
