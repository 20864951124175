import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { inject as Service } from '@ember/service';
import RouteMeta from '../mixins/route-meta';

export default Route.extend(AuthenticatedRouteMixin, RouteMeta, {

    routeMeta : Service('report-meta'),

    beforeModel(){
        this._super(...arguments);
        this.controllerFor('application').set('activeReport', this.get('routeMeta.selectReportPrompt'));
    }
});
