import ReportController from '../controllers/report-controller'
import { computed } from '@ember/object';
import moment from 'moment';
import $ from 'jquery';

export default ReportController.extend({

    queryParams: ['asOf'],
    asOf:null,

    format : "MM/DD/YYYY",
    
    columns: computed(function() {
        return [
        {
            width: '30px',
            sortable: false,
            cellComponent: 'row-toggle',
            breakpoints: ['legacy', 'mobile']
        }, 
        {
          label: '',
          valuePath: 'name',
          width: '200px',
          cellComponent: 'table-cell-key',
          breakpoints: ['legacy', 'mobile', 'tablet', 'desktop', 'jumbo']
        }, 
        {
          label: 'Users',
          valuePath: 'userCount',
          width: '120px',
          breakpoints: ['mobile', 'tablet', 'desktop', 'jumbo']
        }, 
        {
          label: 'Value',
          valuePath: 'value',
          cellComponent:'table-cell-money',
          width: '120px',
          breakpoints: ['legacy', 'mobile', 'tablet', 'desktop', 'jumbo']
        }
    ];
    }),

    getPicker(){
       return $("#reportDatePicker")[0];
    },

    actions:{
      updateReportDate(){
        let picker = this.getPicker()
        let format = this.format;
        let m =moment(picker.value, format)
        this.setProperties({'asOf':m.format('YYYY-MM-DD')}); 
    } ,
  }

});
