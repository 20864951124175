import Router from '../router';

export function initialize(){
  Router.map(function() {
    this.route('login');
  });
}

export default {
    initialize,
    name:  'fundy-web-auth',
    after: 'ember-simple-auth'
  };