export function initialize(instance) {
  
  const applicationRoute = instance.lookup('route:application');
  const session          = instance.lookup('service:session');
  const sessionData      = instance.lookup('service:session-data');
  const store            = instance.lookup('service:store');

  session.on('authenticationSucceeded', function() {
    sessionData.loadCurrentUser().catch((e) => 
    this.session.invalidate()
    );
  });
  
  session.on('invalidationSucceeded', function() {
    store.unloadAll();
    applicationRoute.transitionTo('login');
  });
}
console.log("here");
export default {
  initialize,
  name:  'session-events',
  after: 'fundy-web-auth'
};