import Service from '@ember/service';

export default Service.extend({

    cache : null,

    init(){
        this._super(...arguments);
        this.set('cache', new Map())
    },

    lookup(key){
        return this.cache.get(key);
    },

    save(key, value){
        this.cache.set(key, value);
    },

    isRefresh : false
});
