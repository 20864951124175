import DS from 'ember-data';

export default DS.Model.extend({
  userType: DS.attr('string'),
  email: DS.attr('string'),
  firstName: DS.attr('string'),
  lastName: DS.attr('string'),
  active: DS.attr('boolean'),
  direct: DS.attr('boolean'),
  proServices: DS.attr('boolean'),
  companyName: DS.attr('string'),
  profileImageUrl: DS.attr('string'),
  createdDate: DS.attr('number'),
  lastModified: DS.attr('number'),
  lastLogin: DS.attr('number'),
  profileImage: DS.attr(),
});
