import RangedReport from '../controllers/ranged-report-controller'
import { computed } from '@ember/object';

export default RangedReport.extend({

    columns: computed(function() {
        return [
        {
            width: '30px',
            sortable: false,
            cellComponent: 'row-toggle',
            breakpoints: ['legacy', 'mobile']
        }, 
        {
          label: '',
          valuePath: 'name',
          width: '230px',
          cellComponent: 'table-cell-key',
          breakpoints: ['legacy', 'mobile', 'tablet', 'desktop', 'jumbo']
        }, 
        {
          label: 'Total',
          valuePath: 'total',
          width: '120px',
          cellComponent:'table-cell-money',
          breakpoints: ['legacy', 'mobile', 'tablet', 'desktop', 'jumbo']
        }, 
        // {
        //   label: 'Paypal',
        //   valuePath: 'paypal',
        //   width: '110px',
        //   cellComponent:'table-cell-money',
        //   breakpoints: ['tablet', 'desktop', 'jumbo']
        // }, 
        {
          label: 'Chargify',
          valuePath: 'chargify',
          width: '110px',
          cellComponent:'table-cell-money',
          breakpoints: ['tablet', 'desktop', 'jumbo']
        }, 
        {
          label: 'Authorize',
          valuePath: 'authorize',
          width: '110px',
          cellComponent:'table-cell-money',
          breakpoints: ['tablet', 'desktop', 'jumbo']
        },
        // {
        //   label: 'Square',
        //   valuePath: 'square',
        //   width: '110px',
        //   cellComponent:'table-cell-money',
        //   breakpoints: ['tablet', 'desktop', 'jumbo']
        // },
        // {
        //   label: 'Undetermined',
        //   valuePath: 'undetermined',
        //   width: '120px',
        //   cellComponent:'table-cell-money',
        //   breakpoints: ['tablet', 'desktop', 'jumbo']
        //   }
    ];
    }),

});
