import RSVP from 'rsvp';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default Service.extend({
  
    session: service('session'),
    store: service(),
    user : null,

    loadCurrentUser() {
        return new RSVP.Promise((resolve, reject) => {
            const userId = this.get('session.data.authenticated.userId');
            if (!isEmpty(userId)) {
                this.store.find('user', userId).then((user) => {
                this.set('user', user);
                resolve();
                }, reject);
            } else {
                resolve();
            }
        });
    }
});