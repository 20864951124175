import Service from '@ember/service';
import { computed } from '@ember/object';
import { inject } from '@ember/service';


export default Service.extend({

    router: inject(),

    selectReportPrompt : "Choose a Report",

   revenue :  computed(function() {
      let meta = {  
        name: "Revenue",
        icon: "account-balance",
        route: "revenue"
        };
        return meta;
    }),

    users :  computed(function() {
        let meta = {  
          name: "Users",
          icon: "group",
          route: "users"
          };
          return meta;
      }),

      proEnhancements :  computed(function() {
        let meta = {  
          name: "Pro Enhancements",
          icon: "all-inclusive",
          route: "pro-enhancements"
          };
          return meta;
      }),

      opportunities :  computed(function() {
        let meta = {  
          name: "Opportunities",
          icon: "local-bar",
          route: "opportunities"
          };
          return meta;
      }),

      projectedRevenue :  computed(function() {
        let meta = {  
          name: "Projected Revenue",
          icon: "attach-money",
          route: "projected-revenue"
          };
          return meta;
      }),

      analytics :  computed(function() {
        let meta = {  
          name: "Analytics",
          icon: "trending-up",
          route: "analytics"
          };
          return meta;
      }),

    goToOpportunitiesReport: function(){
        this.router.transitionTo(this.get('opportunities.route'));
    },

    goToUsersReport: function(){
        this.router.transitionTo(this.get('users.route'));
    },

    goToRevenueReport : function(){
        this.router.transitionTo(this.get('revenue.route'));
    },

    goToProjectedRevenueReport: function(){
        this.router.transitionTo(this.get('projectedRevenue.route'));
    },

    goToPEReport: function(){
        this.router.transitionTo(this.get('proEnhancements.route'));
    },

    goToAnalyticsReport: function(){
        this.router.transitionTo(this.get('analytics.route'));
    },


   });
