import RangedReport from '../controllers/ranged-report-controller'
import { computed } from '@ember/object';

export default RangedReport.extend({
    // pickerSince: computed(function() {
    //   return new Date();
    // }),

    columns: computed(function() {
        return [
        {
            width: '30px',
            sortable: false,
            cellComponent: 'row-toggle',
            breakpoints: ['legacy', 'mobile']
        }, 
        {
          label: '',
          valuePath: 'name',
          width: '200px',
          cellComponent: 'table-cell-key',
          breakpoints: ['legacy', 'mobile', 'tablet', 'desktop', 'jumbo']
        }, 
        {
          label: 'Total',
          valuePath: 'totalAmount',
          width: '120px',
          cellComponent:'table-cell-money',
          breakpoints: ['legacy', 'mobile','tablet', 'desktop', 'jumbo']
        },
        {
            label: 'Active Amount',
            valuePath: 'activeAmount',
            width: '120px',
            cellComponent:'table-cell-money',
            breakpoints: ['mobile','tablet', 'desktop', 'jumbo']
          },
          {
            label: 'Trial Amount',
            valuePath: 'trialAmount',
            width: '120px',
            cellComponent: 'table-cell-money',
            breakpoints: ['mobile','tablet', 'desktop', 'jumbo']
          }
    ];
    }),

});
