import RangedReportRoute from '../routes/ranged-report-route'
import { getOwner } from "@ember/application";
import {inject as service } from "@ember/service";

export default RangedReportRoute.extend({

    revenueAdapter : null,

    reportCache: service('report-cache'),

    beforeModel(){
        this._super(...arguments);
        this.controllerFor('application').set('activeReport', this.get('routeMeta.proEnhancements.name'));
        this.controllerFor('loading').set('activeReport', this.get('routeMeta.proEnhancements.name'));
        let applicationInstance = getOwner(this);
        this.set('revenueAdapter', applicationInstance.lookup('adapter:insights'));
    },

    model(params){
    
        this._super(...arguments);
        console.log('debug2')
        let self = this;
        let since = this.since;
        let until = this.until;
        let isRefresh = this.reportCache.isRefresh;
        let lastRefreshTime;

        console.log(`params: ${JSON.stringify(params)}`)
        console.log(this.reportCache.isRefresh)
        let cachedReport = this.getCachedReport();

        if (cachedReport && !isRefresh){
            return cachedReport;
        }
        console.log('debug3')
       let revenueAdapter = this.revenueAdapter;
       let reportObject = null;
       return revenueAdapter.getProEnhancementsBillingReport(since, until, isRefresh).then(               
               function(result){
                   lastRefreshTime = new Date(result.pop()).toLocaleString()
                   reportObject=result;
                   const blankLine = {
                        userCount: "",
                        countOnly: true
                    };
                    let report = [];
                    reportObject.forEach(reportItem => {report.push(
                        [
                            reportItem.totalCusts,
                            reportItem.numActive,
                            blankLine,
                            blankLine,
                            reportItem.dropoff1y,
                            reportItem.dropoff2y,
                            reportItem.dropoff3y
                        ]
                        )})
                    //Num trialing only applies to PE subscriptions, which is the first report in the array.
                    //Remove the second blank line from the PE report and insert the numTrialing.
                    report[0].splice(2,1,reportObject[0].numTrialing);
                    report[0].splice(4, 0,reportObject[0].noOptIn)
                    report[0].splice(5, 0,reportObject[0].dropoff3m)
                    //Add monthly dropoff data to monthly report.
                    report[2].splice(4, 0,reportObject[2].dropoff1m)
                    report[2].splice(5, 0,reportObject[2].dropoff2m)
                    report[2].splice(6, 0,reportObject[2].dropoff3m)
                    
                    // Uncomment these timepoints as the data become relevant for the month lease
                    // report[2].splice(7, 0,reportObject[2].dropoff4m)
                    // report[2].splice(8, 0,reportObject[2].dropoff5m)
                    // report[2].splice(9, 0,reportObject[2].dropoff6m)

                    //Removes the year timepoints from the month lease report.
                    //Chance splice as timepoints become relevant.
                    report[2].splice(-3,3)

                    console.log('result')
                    console.log(result)
                    console.log('report object')
                    console.log(reportObject);
                    console.log('last refresh: ', lastRefreshTime)
                    let retVal = {reportObject:reportObject,  report: report, lastRefreshTime};
                    self.cacheReport(retVal);
               
               return retVal;
           }
       )
    },

    
    afterModel(model, transition) {
        this.set('reportCache.isRefresh',false);
    },

    

});
