import { oneWay } from '@ember/object/computed';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import RouteMeta from '../mixins/route-meta';
import { inject as Service } from '@ember/service';
import Table from 'ember-light-table';

export default Controller.extend(RouteMeta, {

    store: Service(),
    router: Service(),

    isLoading: oneWay('fetchRecords.isRunning'),

    table: computed('model', function() {
        if (this.get('router.currentRouteName') == "pro-enhancements") {
            let table = [];
            let reportArray = this.get('model.report');
            reportArray.forEach(report => table.push(Table.create({ columns: this.get('columns'), rows: report })));
            return table
        }
        let table = Table.create({ columns: this.get('columns'), rows: this.get('model.report') });
        return table;
       }),
    
    actions : {
        onAfterResponsiveChange(matches) {
            if (!this.get('router.currentRouteName') == "pro-enhancements" && matches.indexOf('jumbo') > -1) {
                this.get('table.expandedRows').setEach('expanded', false);
            }
        }
    }

});
