import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
    
    dateValue : computed('value', function(){
        let dateVal = new Date(this.value);
        return dateVal;
    })
    
});
