import ReportController from '../controllers/report-controller'
import { computed } from '@ember/object';

export default ReportController.extend({

   columns: computed(function() {
        return [
        {
          label: '',
          valuePath: 'name',
          width: '200px',
          cellComponent: 'table-cell-key',
          breakpoints: ['legacy', 'mobile', 'tablet', 'desktop', 'jumbo']
        }, 
        {
          label: '',
          valuePath: 'value',
          width: '100px',
          cellComponent: 'table-cell-percent',
          breakpoints: ['legacy', 'mobile', 'tablet', 'desktop', 'jumbo']
        }
    ];
    }),

});
