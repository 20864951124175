import ApplicationAdapter from './application';
import $ from 'jquery';

export default ApplicationAdapter.extend({

  getProductOwnershipReport(since, until){
    let baseUrl = this.host;
    let fullUrl = `${baseUrl}insights/users/products?since=${since}&until=${until}`;
    return $.ajax({url: fullUrl, type:'GET', contentType:'application/json', headers:this.headers});
  },

  getOpportunitiesReport(asOf){
    let baseUrl = this.host;
    let fullUrl = `${baseUrl}insights/users/opportunities?asOf=${asOf}`;
    return $.ajax({url: fullUrl, type:'GET', contentType:'application/json', headers:this.headers});
  },

  getConversionToPurchaseReport(){
    let baseUrl = this.host;
    let fullUrl = `${baseUrl}insights/users/timetoconversion`;
    return $.ajax({url: fullUrl, type:'GET', contentType:'application/json', headers:this.headers});
  },

  getAnalyticsReport(since, until){
    let baseUrl = this.host;
    let fullUrl = `${baseUrl}insights/analytics?since=${since}&until=${until}`;
    return $.ajax({url: fullUrl, type:'GET', contentType:'application/json', headers:this.headers});
  },

  getRevenueReport(since, until){
    // let baseUrl = this.host;
    let baseUrl = `https://report-server-production.fundycentral.com/`;
    // baseUrl = `http://127.0.0.1:3141/`
    let fullUrl = `${baseUrl}revenue-report?since=${since}&until=${until}`;
    return $.ajax({url: fullUrl, type:'GET', contentType:'application/json', headers:this.headers});
  },

  getProEnhancementsBillingReport(since, until, isRefresh=false){
    // let baseUrl = this.host;
    let baseUrl = `https://report-server-production.fundycentral.com/`;
    // let fullUrl = `${baseUrl}insights/revenue/pro-enhancements-billing?since=${since}&until=${until}`;
    // baseUrl = `http://127.0.0.1:3141/`
    let fullUrl = isRefresh ? `${baseUrl}customer-events/refresh` : `${baseUrl}customer-events/drop-offs`
    return $.ajax({url: fullUrl, type:'GET', contentType:'application/json', headers:this.headers});
  },

  getProjectedRevenueReport(since, until){
    // let baseUrl = this.host;
    let baseUrl = `https://report-server-production.fundycentral.com/`;
    // baseUrl = `http://127.0.0.1:3141/`
    // let fullUrl = `${baseUrl}insights/revenue/projected-revenue?since=${since}&until=${until}`;
    let fullUrl = `${baseUrl}projected-revenue?since=${since}&until=${until}`;
    return $.ajax({url: fullUrl, type:'GET', contentType:'application/json', headers:this.headers});
  }
});
