define("fundy-insights/authenticators/fs-auth", ["exports", "fundy-web-auth/authenticators/fs-auth"], function (_exports, _fsAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fsAuth.default;
    }
  });
});
