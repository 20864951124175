import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';
import RouteMeta from '../mixins/route-meta';

export default Route.extend(ApplicationRouteMixin, RouteMeta, {
    
  session: service('session'),
  sessionData: service('session-data'),
    
    beforeModel() {
        this.controllerFor('application').set('activeReport', this.get('routeMeta.selectReportPrompt'));
        this._loadCurrentUser();
    },

    model(){},
  
    _loadCurrentUser(id) {
      return this.sessionData.loadCurrentUser(id).catch((e) => 
        this.session.invalidate()
      );
    }

    
  });
