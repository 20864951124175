import RangedReportRoute from '../routes/ranged-report-route';
import { getOwner } from "@ember/application";
import moment from 'moment';

export default RangedReportRoute.extend({

    beforeModel(){
        this._super(...arguments);
        this.controllerFor('application').set('activeReport', this.get('routeMeta.projectedRevenue.name'));
        this.controllerFor('loading').set('activeReport', this.get('routeMeta.projectedRevenue.name'));
        let applicationInstance = getOwner(this);
        this.set('revenueAdapter', applicationInstance.lookup('adapter:insights'));
    },

    model(params){
      
        this._super(...arguments);
        let self = this;
        let since = this.since;
        let until = this.until;
        let untilDate = new Date(until)

        let cachedReport = this.getCachedReport();
        if (cachedReport){
           return cachedReport;
        }

       let revenueAdapter = this.revenueAdapter;
       let reportObject = null;

    //    if (!moment(until).isSameOrAfter(moment())){  
       if (!untilDate > new Date()){  
        reportObject={};
        // console.log(until)
        // console.log(moment())
        // console.log('Is this where I am?')
        reportObject.proEnhancementsProjection = {name: "Pro Enhancements", trialAmount:0, activeAmount:0, totalAmount:0};
        reportObject.monthLeaseProjection =  {name: "Pro Suite Month Lease", trialAmount:0, activeAmount:0, totalAmount:0};
        reportObject.yearLeaseProjection =  {name: "Pro Suite Year Lease", trialAmount:0, activeAmount:0, totalAmount:0};
        reportObject.totalProjection  = {name: "Total", trialAmount:0, activeAmount:0, totalAmount:0};
        let report = [
            reportObject.proEnhancementsProjection,
            reportObject.monthLeaseProjection,
            reportObject.yearLeaseProjection,
            reportObject.totalProjection,
     
        ]
        return {reportObject:reportObject,  report:report};
       }

       return revenueAdapter.getProjectedRevenueReport(since, until).then(
           function(result){
               reportObject=result;
               console.log(result);
               let report = [
                reportObject.proEnhancementsProjection,
                reportObject.monthLeaseProjection,
                reportObject.yearLeaseProjection,
                reportObject.totalProjection,
            
               ]
               let retVal = {reportObject:reportObject,  report:report};
               self.cacheReport(retVal); 
               return retVal;
           }
       )
    }
});
