import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import RouteMeta from '../mixins/route-meta';
import { getOwner } from "@ember/application";
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import ReportCache from '../mixins/report-cache';

export default Route.extend(AuthenticatedRouteMixin, RouteMeta, ReportCache, {

    asOf: null,
   
    queryParams: {
        asOf: {
            refreshModel: true
        }
      },

    insightsAdapter : null,

    beforeModel(){
        this._super(...arguments);
        this.controllerFor('application').set('activeReport', this.get('routeMeta.opportunities.name'));
        this.controllerFor('loading').set('activeReport', this.get('routeMeta.opportunities.name'));
        let applicationInstance = getOwner(this);
        this.set('insightsAdapter', applicationInstance.lookup('adapter:insights'));
    },

    model(queryParams){
      
        this._super(...arguments);
        let self = this;
        let asOf = queryParams.asOf;
        let now = moment();
        if (isEmpty(asOf)){
            asOf = moment({year: now.year(), month : now.month(), day:1}).format("YYYY-MM-DD");  
        }
       
        this.set('asOf',asOf); 
        this.set('reportKey', `${this.get('routeMeta.opportunities.name')}_${asOf}` )
      
        let cachedReport = this.getCachedReport();
        if (cachedReport){
           return cachedReport;
        }
     
        let insightsAdapter = this.insightsAdapter;
        let reportObject = null;
       
        return insightsAdapter.getOpportunitiesReport(asOf).then(
           function(result){
               reportObject=result;
               let report = [
                reportObject.v6Users,
                reportObject.v7Users,
                reportObject.noProEnhancements,
                reportObject.total,
            ]
            let retVal = {reportObject:reportObject,  report:report};
            self.cacheReport(retVal); 
            return retVal;
           }
       )
    },

});
