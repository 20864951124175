import Mixin from '@ember/object/mixin';
import { inject } from "@ember/service";

export default Mixin.create({

    reportCache : inject('report-cache'),
    cachedReport : null,
    reportKey : null,

    getCachedReport(){
        let key = this.reportKey;
        if (key){
            return this.reportCache.lookup(key); 
        }
    },

    cacheReport(value){
        let key = this.reportKey;
        if (key){
            this.reportCache.save(key, value); 
        }
    }

});
